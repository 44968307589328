import { Action, State } from './types'

export default function reducer(state: State = {}, action: Action): State {
  const key = action.namespace
  const namespace = state[key]

  switch (action.type) {
    case 'SET_LOADING': {
      return {
        ...state,
        [key]: {
          ...namespace,
          isLoading: true,
          isInitialized: true,
          result: null,
          error: null,
          cancelRequest: action.data,
        },
      }
    }
    case 'TOKEN_CONSUMED': {
      return {
        ...state,
        [key]: {
          ...namespace,
          cancelRequest: null,
        },
      }
    }
    case 'LOADING_FAILURE': {
      return {
        ...state,
        [key]: {
          ...namespace,
          isLoading: false,
          error: action.data,
          cancelRequest: null,
        },
      }
    }
    case 'LOADING_SUCCESS': {
      return {
        ...state,
        [key]: {
          ...namespace,
          isLoading: false,
          result: action.data,
          cancelRequest: null,
        },
      }
    }
    case 'CLEANUP': {
      const newState = { ...state }
      delete newState[key]
      return newState
    }
    default:
      return state
  }
}
