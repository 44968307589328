import React from 'react'
import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Container from '@material-ui/core/Container'
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
  iconContainer: {
    textAlign: 'center',
    display: 'inline-block',
  },
  icon: {
    textAlign: 'initial',
    fontSize: '32px',
  },
  flex: {
    flexGrow: 1,
  },
}))

export default () => {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <div className={classes.flex} />
      <div className={classes.iconContainer}>
        <Icon
          color="primary"
          className={clsx('fa-spin', 'fal fa-spinner-third', classes.icon)}
        />
      </div>
      <div className={classes.flex} />
    </Container>
  )
}
