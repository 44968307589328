import React, { Suspense, ExoticComponent } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import LoadingPage from './LoadingPage'
import useUser from 'effects/state/useUser'

const BookingPage = React.lazy(() => import('./BookingPage/Standard')) // prettier-ignore
const NotFound = React.lazy(() => import('./NotFound')) // prettier-ignore
const OwnerWizard = React.lazy(() => import('./_wizards/OwnerWizard')) // prettier-ignore

type Routes = [string, ExoticComponent][]
const routes: Routes = [
  /**
   * Wizards
   */
  ['/wizards/accounts', React.lazy(() => import('./_wizards/AccountWizard'))], // prettier-ignore
  ['/wizards/bookings', React.lazy(() => import('./_wizards/BookingWizard'))], // prettier-ignore
  ['/wizards/checks', React.lazy(() => import('./_wizards/CheckWizard'))], // prettier-ignore
  ['/wizards/members', OwnerWizard], // prettier-ignore
  ['/wizards/members/:id', OwnerWizard], // prettier-ignore
  ['/wizards/memberships', OwnerWizard], // prettier-ignore
  ['/wizards/properties', React.lazy(() => import('./_wizards/PropertyWizard'))], // prettier-ignore
  // ['/wizards/reserve-time',     React.lazy(() => import('./_wizards/BookingReserveTimeWizard'))], // prettier-ignore
  ['/wizards/special-offers', React.lazy(() => import('./_wizards/SpecialOfferWizard'))], // prettier-ignore
  /**
   * Data Lists
   */
  ['/browse/bookings', React.lazy(() => import('./_tables/Bookings'))], // prettier-ignore
  ['/browse/checks', React.lazy(() => import('./ChecksList'))], // prettier-ignore
  ['/browse/guests', React.lazy(() => import('./_tables/Guests'))], // prettier-ignore
  ['/browse/members', React.lazy(() => import('./_tables/Owners'))], // prettier-ignore
  ['/browse/properties', React.lazy(() => import('./_tables/Properties'))], // prettier-ignore
  ['/browse/reserve-time', React.lazy(() => import('./_tables/BookingsReserveTime'))], // prettier-ignore
  ['/browse/resortcompanies', React.lazy(() => import('./_tables/ResortCompanies'))], // prettier-ignore
  ['/luxorts/payments', React.lazy(() => import('./_tables/Payments'))], // prettier-ignore
  ['/luxorts/reservations', React.lazy(() => import('./_tables/BookingsLuxorts'))], // prettier-ignore
  /**
   * Administrative Content
   */
  ['/browse/accountCompanies', React.lazy(() => import('./_cms/AccountCompanies'))], // prettier-ignore
  ['/browse/accountRoles', React.lazy(() => import('./_cms/AccountRoles'))], // prettier-ignore
  ['/browse/accounts', React.lazy(() => import('./_tables/Accounts'))], // prettier-ignore
  ['/browse/accounts/:id', React.lazy(() => import('./AccountPage'))], // prettier-ignore
  ['/browse/banks', React.lazy(() => import('./_cms/Banks'))], // prettier-ignore
  ['/browse/pageGroups', React.lazy(() => import('./_cms/PageGroups'))], // prettier-ignore
  ['/browse/pages', React.lazy(() => import('./_cms/Pages'))], // prettier-ignore
  ['/browse/signups', React.lazy(() => import('./SignupCompanies'))], // prettier-ignore
  ['/browse/timepunches', React.lazy(() => import('./TimepunchManager'))], // prettier-ignore
  /**
   * Reports
   */
  ['/reports/eoq', React.lazy(() => import('./_reports/ReportEOQ'))], // prettier-ignore
  ['/reports/listings-unmanaged', React.lazy(() => import('./_reports/ListingsLite'))], // prettier-ignore
  ['/reports/luxorts-res', React.lazy(() => import('./_reports/LuxortsReservationStatus'))], // prettier-ignore
  ['/reports/payout', React.lazy(() => import('./_reports/PayoutReport'))], // prettier-ignore
  ['/reports/price-changes', React.lazy(() => import('./_reports/ReportListingPriceChanges'))], // prettier-ignore
  ['/reports/profit', React.lazy(() => import('./_reports/ReportProfit'))], // prettier-ignore
  ['/reports/reservations', React.lazy(() => import('./_reports/Reservations'))], // prettier-ignore
  ['/reports/unusedPoints', React.lazy(() => import('./_reports/ReportUnusedPoints'))], // prettier-ignore
  /**
   * Data Management
   */
  ['/browse/bookings/:bookingID', BookingPage], // prettier-ignore
  ['/browse/bookings/:bookingID/:bookingTab', BookingPage], // prettier-ignore
  ['/browse/checks/:id', React.lazy(() => import('./CheckPage'))], // prettier-ignore
  ['/browse/checks/:id/invoice', React.lazy(() => import('./CheckInvoicePage'))], // prettier-ignore
  ['/browse/checks/:id/print', React.lazy(() => import('./CheckPrintPage'))], // prettier-ignore
  ['/browse/guests/:guestID?/:guestTab?', React.lazy(() => import('./GuestPage'))], // prettier-ignore
  ['/browse/members/:memberID/:memberTab?/:membershipID?', React.lazy(() => import('./OwnerPage'))], // prettier-ignore
  ['/browse/resortCompanies/:resortCompanyID/:resortCompanyTab?', React.lazy(() => import('./ResortCompanyPage'))], // prettier-ignore
  ['/browse/reserve-time/:bookingID', React.lazy(() => import('./BookingPage/ReserveTime'))], // prettier-ignore
  ['/browse/resorts/:resortID/:resortTab?', React.lazy(() => import('./ResortPage'))], // prettier-ignore
  ['/browse/units/:unitID/:unitTab?', React.lazy(() => import('./UnitPage'))], // prettier-ignore
  ['/luxorts/reservations/:id', React.lazy(() => import('./BookingPage/Luxorts'))], // prettier-ignore
  /**
   * Uncategorized
   */
  ['/luxorts/verify-user', React.lazy(() => import('./FixLuxortsUser'))], // prettier-ignore
  ['/profile', React.lazy(() => import('./Profile'))], // prettier-ignore
  ['/', React.lazy(() => import('./Dashboard'))], // prettier-ignore
]

function AuthedScenes() {
  const { id } = useUser()

  if (typeof id === 'undefined') {
    const { pathname, search, hash } = window.location

    return (
      <Redirect
        to={'/login?redirect=' + encodeURIComponent(pathname + search + hash)}
      />
    )
  }

  return (
    <Switch>
      {routes.map(([path, component], key) => (
        <Route exact path={path} component={component} key={key} />
      ))}
      <Route component={NotFound} />
    </Switch>
  )
}

export default function () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <AuthedScenes />
    </Suspense>
  )
}
