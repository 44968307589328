import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

// all authorized-only scenes
import AuthorizedScenes from './scenes-authed'

// external routes
import LoginPage from './LoginPage'

export default function Scenes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <AuthorizedScenes />
      </Switch>
    </BrowserRouter>
  )
}
