import { fetch } from 'redux/FetchRequests/actions'
import {
  AUTH_NAMESPACE,
  AUTH_LOAD_TIMEPUNCH_NAMESPACE,
  AUTH_TIMEPUNCH_NAMESPACE,
} from 'redux/FetchRequests/namespaces'

import {
  USER_SET_CONNECTED_USER,
  USER_SET_PUNCHES,
  USER_TIMEPUNCH_SET_STATUS,
} from './actions-simple'

/**
 * Loads the current user session and stores it in state
 */
export const getSession = () => (dispatch) => {
  dispatch(fetch(AUTH_NAMESPACE, '/v1/sessions?action=get')).then((user) => {
    dispatch(USER_SET_CONNECTED_USER(user))

    if (user) {
      dispatch(loadPunches())
    }
  })
}

/**
 * Loads the users timepunches for the week
 */
export const loadPunches = () => (dispatch) => {
  dispatch(
    fetch(AUTH_LOAD_TIMEPUNCH_NAMESPACE, '/v1/timepunches?action=read_for_week')
  )
    .then((result) => {
      dispatch(USER_SET_PUNCHES(result))
    })
    .catch(window.toastr.error)
}

/**
 * Punches the user in or out
 */
export const timepunch = () => (dispatch, getState) => {
  dispatch(
    fetch(AUTH_TIMEPUNCH_NAMESPACE, '/v1/timepunches', {
      method: 'POST',
      data: {
        action: 'punch',
        data: {
          token: Math.random().toString(36),
        },
      },
    })
  )
    .then(({ state: { clocked_in } }) => {
      dispatch(USER_TIMEPUNCH_SET_STATUS(clocked_in))
      dispatch(loadPunches())
    })
    .catch(window.toastr.error)
}
