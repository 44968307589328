import { useMemo } from 'react'
import { useAppSelector } from 'effects/redux'
import { DataNamespace } from 'redux/FetchRequests/types'

export default function <DataType = any>(
  namespace: string
): DataNamespace<DataType> {
  const namespaces = useAppSelector((state) => state.FetchRequests)
  const ns = namespaces[namespace]

  return useMemo(() => {
    if (!ns) {
      return {
        isLoading: false,
        isInitialized: false,
        error: null,
        result: null,
        cancelRequest: null,
      }
    }

    return {
      isLoading: ns.isLoading,
      isInitialized: ns.isInitialized,
      error: ns.error,
      result: ns.result,
      cancelRequest: ns.cancelRequest,
    }
  }, [ns])
}
