import storage from 'local-storage-fallback'
import { AppDispatch, GetAppState } from 'redux/store'
import { State } from './initial'

/**
 * updates a single key/value pair in localstorage
 */
export function set(key: keyof State, value: any) {
  return function (dispatch: AppDispatch, getState: GetAppState) {
    const newStorageState = JSON.stringify({
      ...getState().Storage,
      [key]: value,
    })

    storage.setItem('store', newStorageState)

    dispatch({
      type: 'STORAGE_SET',
      key,
      value,
    })
  }
}

/**
 * reads localstorage and updates our current storage to match it, should be fired whenever
 * a change event is detected
 */
export function sync(value: any) {
  return function (dispatch: AppDispatch, getState: GetAppState) {
    dispatch({
      type: 'STORAGE_RESYNC',
      data: {
        ...getState().Storage,
        ...value,
      },
    })
  }
}
