// redux
import { useDispatch } from 'react-redux'
import { getSession } from 'redux/User/actions'

// themeing
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { GlobalStyles, useCreateTheme } from 'theme'

import { ToastContainer } from 'react-toastify'
import BackgroundTasks from 'components/BackgroundTasks'
import useMount from 'effects/useMount'
import useRequestState from 'effects/state/useRequestState'
import Scenes from 'scenes'
import LoadingPage from 'scenes/LoadingPage'
import ErrorPage from 'scenes/ErrorPage'

import { AUTH_NAMESPACE } from 'redux/FetchRequests/namespaces'

export default function App() {
  const {
    isLoading: loading,
    isInitialized: initialized,
    error,
  } = useRequestState(AUTH_NAMESPACE)

  const dispatch = useDispatch()
  const theme = useCreateTheme()

  // autoload session data
  useMount(() => {
    dispatch(getSession())
  })

  if (!initialized) {
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <ToastContainer />
      <BackgroundTasks />
      {loading ? <LoadingPage /> : error ? <ErrorPage /> : <Scenes />}
    </ThemeProvider>
  )
}
