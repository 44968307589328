import Axios from 'axios'
import qs from 'qs'
import { getAPIURL } from 'utils/url'

import { AxiosPromise, AxiosRequestConfig, Canceler } from 'axios'

export function request(
  url: string,
  options: AxiosRequestConfig = {}
): [Canceler, AxiosPromise] {
  // setup a cancel token / store a ref to the canceller
  let invokeCancelFn: Canceler = () => null
  let cancelToken = new Axios.CancelToken((canceller) => {
    invokeCancelFn = canceller
  })

  const requestOptions: AxiosRequestConfig = {
    withCredentials: true,
    method: 'GET',
    paramsSerializer: (params) => qs.stringify(params),
    ...options,
    cancelToken: cancelToken,
    url: getAPIURL(url),
  }

  if (requestOptions.data) {
    requestOptions.data = qs.stringify(requestOptions.data)
  }

  const promise = Axios.request(requestOptions).then(handleResponse)

  return [invokeCancelFn, promise]
}

/**
 * We do this to make any requests that have 200 ok but a status of false
 * behave as if it were a RESTful failure. Legacy support because I'm an idiot
 */
export function handleResponse(response: any): any {
  if (!response.data.status) {
    // we have to do some remapping to make this look like what the error handler expects
    // eslint-disable-next-line no-throw-literal
    throw {
      // we have to do some remapping to make this look like what the error handler expects
      response: {
        status: 400,
        data: {
          code: 400,
          ...response.data,
        },
      },
    }
  }

  return response
}

export function getErrorText(e: any): string {
  if (e.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (typeof e.response.data === 'object') {
      // e.response.data contains the server message: "{status, code, data}"
      return `[${e.response.status}] ${e.response.data.message}`
    }

    // it's probably a network error, e.response.data won't be an object
    return `[${e.response.status}] ${e.response.data}`
  }

  if (e.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return `No connection to the server`
  }

  // Something happened in setting up the request that triggered an Error
  // we want to pass the error outside of the scope of our try/catch here
  throw e
}

export function interpretError(e: any): string {
  let message = getErrorText(e)

  // log to console
  if (typeof e === 'object' && e.response && e.response.data) {
    console.error('MESSAGE > ', message, '\n\n RESPONSE > ', e.response.data, '\n\n AXIOS > ', { ...e }) // prettier-ignore
  } else {
    console.error('MESSAGE > ', message, '\n\n ERROR > ', e)
  }

  return message
}
