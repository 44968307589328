/**
 * @typedef {import('./types').Action} Action
 */

/** @returns {Action} */
export const USER_TIMEPUNCH_SET_STATUS = (data) => ({
  type: 'USER_TIMEPUNCH_SET_STATUS',
  data,
})

/** @returns {Action} */
export const USER_SET_CONNECTED_USER = (data) => ({
  type: 'USER_SET_CONNECTED_USER',
  data,
})

/** @returns {Action} */
export const USER_SET_AVATAR = (data) => ({
  type: 'USER_SET_AVATAR',
  data,
})

/** @returns {Action} */
export const USER_SET_PUNCHES = (data) => ({
  type: 'USER_SET_PUNCHES',
  data,
})
