import { Action } from './types'

export const SET_LOADING = (namespace: string, data: any): Action => ({
  namespace,
  type: 'SET_LOADING',
  data,
})

export const LOADING_FAILURE = (namespace: string, data: any): Action => ({
  namespace,
  type: 'LOADING_FAILURE',
  data,
})

export const LOADING_SUCCESS = (namespace: string, data: any): Action => ({
  namespace,
  type: 'LOADING_SUCCESS',
  data,
})

export const TOKEN_CONSUMED = (namespace: string): Action => ({
  namespace,
  type: 'TOKEN_CONSUMED',
})

export const CLEANUP = (namespace: string): Action => ({
  namespace,
  type: 'CLEANUP',
})
