import InitialState from './initial.js'

export default (state = InitialState, action) => {
  switch (action.type) {
    case 'USER_SET_CONNECTED_USER':
      return {
        ...state,
        ...action.data,
      }
    case 'USER_TIMEPUNCH_SET_STATUS':
      return {
        ...state,
        clocked_in: action.data,
      }
    case 'USER_SET_PUNCHES':
      return {
        ...state,
        timepunches: action.data,
      }
    case 'USER_SET_AVATAR':
      return {
        ...state,
        avatar: {
          // generate a random query string so it forces a reload
          url: action.data + '?q=' + Math.random().toString(36),
        },
      }
    default:
      return state
  }
}
