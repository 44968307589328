import InitialState from "./initial.js";
export default (state = InitialState, action) => {
  switch (action.type) {
    case "CALENDAR_SET_PROCESSING":
      return {
        ...state,
        processing: true
      };
    case "CALENDAR_CLEAR_CHANGES":
      return {
        ...state,
        changes: []
      };
    case "CALENDAR_DONE_PROCESSING":
      return {
        ...state,
        processing: false
      };
    case "CALENDAR_SET_UNIT_LOADING":
      return {
        ...state,
        unit: false,
        unitLoading: true,
        unitError: false
      };
    case "CALENDAR_SET_UNIT":
      return {
        ...state,
        unit: action.data,
        unitLoading: false,
        unitError: false
      };
    case "CALENDAR_SET_UNIT_ERR":
      return {
        ...state,
        unitLoading: false,
        unit: false,
        unitError: action.data
      };
    case "CALENDAR_GEN_MONTHS":
      return {
        ...state,
        months: action.data
      };
    case "CALENDAR_SET_YEAR":
      return {
        ...state,
        year: action.data
      };
    case "CALENDAR_SET_RANGE_START":
      return {
        ...state,
        rangeStart: action.data.rangeStart,
        rangeStartBlackedOut: action.data.rangeStartBlackedOut
      };
    case "CALENDAR_SET_RANGE_END":
      return {
        ...state,
        rangeEnd: action.data.rangeEnd
      };
    case "CALENDAR_CLEAR_RANGE":
      return {
        ...state,
        rangeStart: false,
        rangeStartBlackedOut: false,
        rangeEnd: false
      };
    case "CALENDAR_ADD_CHANGES":
      const changes = state.changes;
      return {
        ...state,
        changes: changes.concat(action.data)
      };
    case "CALENDAR_UNDO_ONE":
      let lastIndex = false;
      state.changes.forEach((change, index) => {
        if (change.undone === false) {
          lastIndex = index;
        }
      });
      return {
        ...state,
        changes: state.changes.map((change, index) => {
          if (index === lastIndex) {
            change.undone = true;
          }
          return change;
        })
      };
    case "CALENDAR_UNDO_ALL":
      return {
        ...state,
        changes: state.changes.map(change => ({
          ...change,
          undone: true
        }))
      };
    default:
      return state;
  }
};
