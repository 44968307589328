import { request, interpretError } from './helpers'
import {
  TOKEN_CONSUMED,
  SET_LOADING,
  LOADING_SUCCESS,
  LOADING_FAILURE,
} from './actions-simple'

import { AppDispatch, GetAppState } from 'redux/store'

/**
 * cancels a request
 */
export function cancel(namespace: string) {
  return function (dispatch: AppDispatch, getState: GetAppState) {
    const state = getState()

    // try to cancel the current request
    const current = state.FetchRequests[namespace]

    if (current && typeof current.cancelRequest === 'function') {
      current.cancelRequest()
      dispatch(TOKEN_CONSUMED(namespace))
    }
  }
}

/**
 * sends a request
 */
export function fetch(namespace: string, url: string, options: any = {}) {
  return async function (dispatch: AppDispatch) {
    dispatch(cancel(namespace))

    try {
      const [cancelRequest, axiosPromise] = request(url, options)
      dispatch(SET_LOADING(namespace, cancelRequest))

      const response = await axiosPromise
      dispatch(LOADING_SUCCESS(namespace, response.data.message))

      return response.data.message
    } catch (error: any) {
      // check if this error is a result of the request being cancelled
      // if it is, nothing really needs to be done
      if (error.__CANCEL__) {
        console.warn('cancelled request to %s', url)
        return
      }

      const message = interpretError(error)
      dispatch(LOADING_FAILURE(namespace, message))

      // re-throw so any catchers trigger
      throw message
    }
  }
}
