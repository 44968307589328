import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

import useUser from 'effects/state/useUser'

import AjaxFn from 'services/AjaxFn'

export const useLogin = () => {
  const { id } = useUser()

  const redirection = useGetRedirect()

  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    setError(false)

    AjaxFn({
      url: '/v1/sessions',
      data: {
        action: 'login',
        data: {
          username,
          password,
        },
      },
      success: () => window.location.reload(),
      failure: (error) => {
        setError(error)
        setLoading(false)
      },
    })
  }

  return {
    id,
    redirection,
    loading,
    handleSubmit,
    error,
    username: [username, setUsername],
    password: [password, setPassword],
  }
}

/**
 * returns the url we should redirect to after a successful login
 * @returns {string}
 */
export const useGetRedirect = () => {
  const { search } = useLocation()

  // get our redirect location
  const origin = qs.parse(search, { ignoreQueryPrefix: true }).redirect
  // if it doesn't exist, set it to the dashboard. otherwise, redirect to whatever value it has
  return origin || '/'
}
