import React from 'react'
import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: '20vh',
  },
  flex: {
    flexGrow: 1,
  },
  bug: {
    marginRight: theme.spacing(1),
  },
  actions: {
    textAlign: 'right',
  },
}))

export default () => {
  const classes = useStyles()

  return (
    <Container maxWidth="sm" className={classes.container}>
      <div className={classes.flex} />
      <Paper elevation={2} className={classes.paper}>
        <Typography variant="h5">
          <Icon className={clsx(classes.bug, 'fal fa-bug')} color="error" />
          Something's wrong
        </Typography>
        <br />
        <Typography>
          The application couldn't load. Try refreshing the page.
        </Typography>
        <div className={classes.actions}>
          <Button onClick={() => window.location.reload()}>refresh</Button>
        </div>
      </Paper>
      <div className={classes.flex} />
    </Container>
  )
}
