import { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createAlert, removeAlertByIdentifier } from 'redux/Alertbar/actions'

// we can only run this effect if window.onLine is supported
const supported =
  typeof navigator === 'object' && typeof navigator.onLine === 'boolean'

export default () => {
  // all visible alerts
  const alerts = useSelector((state) => state.Alertbar.alerts)
  // if a disconnected alert exists or not
  const notified = useMemo(
    () => alerts.some((alert) => alert.identifier === 'disconnected'),
    [alerts]
  )

  /**
   * Set up our redux actions
   */
  const dispatch = useDispatch()

  // create an alert
  const alert = useCallback(() => {
    dispatch(
      createAlert('disconnected', 'No internet connection', 'disconnected')
    )
  }, [dispatch])

  // remove an alert
  const remove = useCallback(() => {
    dispatch(removeAlertByIdentifier('disconnected'))
  }, [dispatch])

  const handleConnectionChange = useCallback(() => {
    const online = navigator.onLine

    if (!online) {
      // if we just went offline and no alert exists, create one
      if (!notified) alert()
    } else {
      // otherwise if we connected, remove any 'disconnected' alerts
      remove()
    }
  }, [notified, alert, remove])

  useEffect(() => {
    if (supported) {
      window.addEventListener('online', handleConnectionChange)
      window.addEventListener('offline', handleConnectionChange)

      return () => {
        window.removeEventListener('online', handleConnectionChange)
        window.removeEventListener('offline', handleConnectionChange)
      }
    }
  }, [handleConnectionChange])
}
