import { useSelector } from 'react-redux'

import { makeStyles, createTheme, lighten } from '@material-ui/core/styles'

import blue from '@material-ui/core/colors/blue'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'
import yellow from '@material-ui/core/colors/yellow'
import red from '@material-ui/core/colors/red'
import { useMemo } from 'react'

export const useCreateTheme = () => {
  // @ts-ignore
  const theme = useSelector((state) => state.Storage.theme || 'light')
  // @ts-ignore
  const font = useSelector((state) => state.Storage.font || "'Open Sans'")

  return useMemo(() => {
    // Change the background color
    const background =
      theme === 'dark'
        ? {
            default: '#1e1e20',
            paper: lighten('#1e1e20', 0.05),
          }
        : {}

    const tables =
      theme === 'light'
        ? {
            green: {
              background: green[50],
              color: 'inherit',
            },
            yellow: {
              background: yellow[50],
              color: 'inherit',
            },
            red: {
              background: red[50],
              color: 'inherit',
            },
            info: {
              background: blue[50],
              color: 'inherit',
            },
          }
        : {
            green: {
              background: 'transparent',
              color: green[400],
            },
            yellow: {
              background: 'transparent',
              color: yellow[500],
            },
            red: {
              background: 'transparent',
              color: red[500],
            },
            info: {
              background: 'transparent',
              color: blue[500],
            },
          }

    const muiTheme = createTheme({
      tables,
      palette: {
        type: theme,
        primary: blue,
        secondary: grey,
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        // override the background color
        background,
      },
      typography: {
        fontFamily: [font, 'Arial', 'Helvetica', 'sans-serif'].join(','),
      },
      shape: {
        borderRadius: 0,
      },
    })

    if (process.env.NODE_ENV === 'development') {
      console.log('theme', muiTheme)
    }

    return muiTheme
  }, [font, theme])
}

const useGlobalStyles = makeStyles(() => ({
  '@global': {
    ':root': {
      '--portalBlue': '#2185D0',
      '--portalBlack': '#1B1C1D',
      '--portalBlackLighter': '#28292A',
      '--portalBlackLightest': '#343637',
      '--portalGrey': '#E0E1E2',
      '--offWhite': '#F2F2F3',
      '--breadcrumbDivider': '#B2B5B8',
      '--danger': '#EE4545',
    },
    '.ui.modals': {
      zIndex: 1500,
    },
    '.ui.sidebar': {
      overflow: 'visible !important',
    },
    // fontawesome icon support
    'i.far, i.fal, i.fas': {
      fontFamily: 'Font Awesome 5 Pro',
    },
    // auto-sizes icons to the text they're within
    '.MuiTypography-root > .MuiIcon-root': {
      fontSize: 'inherit',
    },
    // ensure icons dont clip
    '.MuiIcon-root': {
      overflow: 'visible !important',
    },
    // allow fixed-width icons
    '.fa-fw': {
      width: '2em !important',
    },
    // disable anchor styling on some clickables
    'a.MuiIconButton-root': {
      textDecoration: 'none !important',
    },
    // custom anchor styling
    a: {
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    'a[disabled]': {
      pointerEvents: 'none',
    },
    // autocomplete styling
    'input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill':
      {
        backgroundColor: '#2a2b2b !important',
      },
    // print styling
    '@media print': {
      '#navbar, #breadcrumb, #focus-bar, #footer': {
        display: 'none !important',
      },
      '#content': {
        overflowY: 'visible',
      },
    },
  },
}))

export const GlobalStyles = () => {
  useGlobalStyles()

  return null
}
