import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducer.js'

const enhancers = []
const middleware = [thunk]

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === 'function') {
    enhancers.push(
      devToolsExtension({
        stateSanitizer: (state: any) => {
          return {
            ...state,
            ContextBar: '<< TRIMMED >>',
          }
        },
        actionSanitizer: (action: any) => {
          if (action.type === 'CONTEXT_BAR_SET_CONTENT') {
            return {
              type: 'CONTEXT_BAR_SET_CONTENT',
              data: '<< TRIMMED >>',
            }
          }

          return action
        },
      })
    )
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(reducer, {}, composedEnhancers)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type GetAppState = () => RootState

export default store
