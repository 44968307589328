import useCheckOffline from 'effects/useCheckOffline'
import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { sync } from 'redux/Storage/actions'

export default function BackgroundTasks() {
  useSubscribeToStorage()
  useCheckOffline()

  return null
}

/**
 * Define a quick helper fn to subscribe to localstorage
 *
 * When there is a change, it dispatches to redux via the sync action
 */
function useSubscribeToStorage() {
  const dispatch = useDispatch()

  const listener = (event: any) => {
    if (event.newValue && event.newValue !== '1') {
      try {
        const value = JSON.parse(event.newValue)
        dispatch(sync(value))
      } catch (e) {
        console.error('Invalid JSON:', event.newValue)
      }
    }
  }

  const listenerCallback = useCallback(listener, [dispatch])

  useEffect(() => {
    window.addEventListener('storage', listenerCallback, false)

    return () => window.removeEventListener('storage', listenerCallback)
  }, [listenerCallback])
}
