import storage from 'local-storage-fallback'

export type State = {
  theme: 'light' | 'dark'
  font: string
  focusBarOpen: boolean
  favoritePages: any[]
}

export default {
  theme: 'light',
  font: "'Open Sans'",
  focusBarOpen: true,
  favoritePages: [],
  ...JSON.parse(storage.getItem('store') || '{}'),
}
