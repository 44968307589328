import * as Sentry from "@sentry/browser";

/**
 * This requires an .env file to be made on the production server in the /react
 * directory with an entry for REACT_APP_SENTRY_DSN.
 *
 * If REACT_APP_SENTRY_DSN is not found, sentry will not init.
 *
 * React will automatically set NODE_ENV to production during npm `run-script build`.
 */
const { REACT_APP_SENTRY_DSN, NODE_ENV } = process.env;
if (NODE_ENV !== "development") {
  if (REACT_APP_SENTRY_DSN) {
    console.log("sentry initialized");
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN
    });
  }
}
