import React from 'react'

import { Button, Icon, ButtonProps, IconProps } from '@material-ui/core'

export type Props = ButtonProps & {
  LoaderProps?: IconProps
  loading: boolean
  children?: any
}

/**
 * Button with the ability to display a loading state
 * Pass loading=true to display a spinner, control the spinner
 * by passing LoaderProps
 */
export default function LoadingButton(props: Props) {
  const { children, loading, LoaderProps, ...rest } = props

  const defaultLoaderProps: IconProps = {
    className: 'fal fa-fw fa-spin fa-spinner-third',
  }

  return (
    <Button {...rest}>
      {loading ? <Icon {...defaultLoaderProps} {...LoaderProps} /> : children}
    </Button>
  )
}

LoadingButton.defaultProps = {
  loading: false,
}
