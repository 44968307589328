import InitialState, { State } from './initial'

export default (state: State = InitialState, action: any): State => {
  switch (action.type) {
    case 'STORAGE_SET':
      return {
        ...state,
        [action.key]: action.value,
      }
    case 'STORAGE_RESYNC':
      return {
        ...action.data,
      }
    default:
      return state
  }
}
