import React from 'react'
import { Redirect } from 'react-router-dom'

import {
  Container,
  Grid,
  Typography,
  InputAdornment,
  TextField,
  Icon,
  Paper,
  makeStyles,
} from '@material-ui/core'

import { useLogin } from './LoginPage.hooks'
import LoadingButton from 'components/_simple/LoadingButton'

const Login = () => {
  const classes = useStyles()
  const {
    id,
    redirection,
    loading,
    handleSubmit,
    error,
    username: [username, setUsername],
    password: [password, setPassword],
  } = useLogin()

  if (typeof id !== 'undefined') {
    return <Redirect to={redirection} />
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.loginContainer}>
        <div className={classes.logo}>
          <Icon className="fal fa-fw fa-lambda" color="primary" />
        </div>
        <Paper className={classes.loginPaper}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="textSecondary"
                  gutterBottom
                  align="center"
                  style={{ fontWeight: 'bold' }}
                >
                  Welcome Back
                </Typography>
                <Typography color="textSecondary" gutterBottom align="center">
                  Please log in to continue
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  name="username"
                  placeholder="Username"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className="fal fa-user-circle" color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  disabled={loading}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  name="password"
                  placeholder="Password"
                  type="password"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className="fal fa-lock" color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  disabled={loading}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  loading={loading}
                  type="submit"
                  size="large"
                >
                  log in
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
        {error && (
          <Paper className={classes.errorContainer}>
            <Typography className={classes.error}>{error}</Typography>
          </Paper>
        )}
      </div>
    </Container>
  )
}

export default Login

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    marginTop: '20vh',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  logo: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    '& .MuiIcon-root': {
      fontSize: '4rem',
    },
  },
  loginPaper: {
    padding: theme.spacing(4),
  },
  errorContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: theme.palette.error.dark,
  },
  error: {
    color: theme.palette.error.contrastText,
  },
}))
