import { combineReducers } from 'redux'
import Alertbar from './Alertbar/reducer'
import BookingWizard from './BookingWizard/reducer'
import Calendar from './Calendar/reducer'
import ContextBar from './ContextBar/reducer'
import FetchRequests from './FetchRequests/reducer'
import NewsFeed from './NewsFeed/reducer'
import Sidebar from './Sidebar/reducer'
import Storage from './Storage/reducer'
import TimepunchManager from './TimepunchManager/reducer'
import User from './User/reducer'

export default combineReducers({
  Alertbar,
  BookingWizard,
  Calendar,
  ContextBar,
  FetchRequests,
  NewsFeed,
  Sidebar,
  Storage,
  TimepunchManager,
  User,
})
