import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import store from 'redux/store'
import { Provider } from 'react-redux'

import 'services/sentry'
import 'services/toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import 'moment-duration-format'

import * as Sentry from '@sentry/browser'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('portal')
)
