import { useAppSelector } from 'effects/redux'

/**
 * Returns whether or not the current user is loading, and the user
 *
 * useRequestState is commented out because it isn't needed, but if the
 * network state is needed, it's easy to add
 */
export default function () {
  const user = useAppSelector((state) => state.User)

  return user
}
